<template>
  <div class="vacancy-content">
    <div class="row">
      <VacancyItem
        v-for="(item, index) in vacancies"
        :key="index"
        :item="item"
        :index="index"
        @details="details($event)"
      />
    </div>
    <b-modal
      id="vacancy-details"
      hide-header
      hide-footer
      centered
      scrollable-y
      size="lg"
    >
      <div class="popup details-modal">
        <div class="row">
          <div class="col-12">
            <div class="details-modal__header">
              {{ activeVacancy.title }}
            </div>
            <div
              class="details-modal__body editor"
              v-html="activeVacancy.description"
            ></div>
          </div>
          <div class="col-12 text-center">
            <button class="button button--main" @click.prevent="togglePopup">
              {{ $store.state.statics.Send }}
            </button>
          </div>
        </div>
        <div class="popup__close" @click="$bvModal.hide('vacancy-details')">
          <b-icon :icon="'x'"></b-icon>
        </div>
      </div>
    </b-modal>

    <b-modal
      id="vacancy-apply"
      hide-header
      hide-footer
      centered
      scrollable-y
      size="lg"
    >
      <div class="popup apply-modal">
        <div class="row">
          <div class="col-12">
            <div class="details-modal__header">
              {{ activeVacancy.title }}
            </div>
            <div class="apply-modal__body">
              <VacancyApply :id="activeVacancy.id" />
            </div>
          </div>
        </div>
        <div class="popup__close" @click="$bvModal.hide('vacancy-apply')">
          <b-icon :icon="'x'"></b-icon>
        </div>
      </div>
    </b-modal>
  </div>
</template>
<script>
import VacancyItem from "./VacancyItem";
import VacancyApply from "./VacancyApply";
import { mapState } from "vuex";
export default {
  name: "vacancies",
  components: {
    VacancyItem,
    VacancyApply,
  },
  data() {
    return {
      active: null,
    };
  },
  computed: {
    ...mapState({
      vacancies: (state) => state.vacancies.vacancies,
    }),
    activeVacancy() {
      if (this.active != null) {
        return this.vacancies[this.active];
      }
      return {};
    },
  },
  methods: {
    details(e) {
      this.active = e;
      this.$bvModal.show("vacancy-details");
    },
    togglePopup() {
      this.$bvModal.hide("vacancy-details");
      this.$bvModal.show("vacancy-apply");
    },
  },
};
</script>