<template>
  <div class="vacancy-apply mb-4">
    <div class="row">
      <div class="col-lg-6">
        <div class="form-group">
          <input-row :label="name" v-model="form.name" />
        </div>
      </div>
      <div class="col-lg-6">
        <div class="form-group">
          <input-row :label="surname" v-model="form.surname" />
        </div>
      </div>
      <div class="col-lg-6">
        <div class="form-group">
          <input-row :label="phone" v-model="form.phone" />
        </div>
      </div>
      <div class="col-lg-6">
        <div class="form-group">
          <input-row :label="email" v-model="form.email" />
        </div>
      </div>
      <div class="col-lg-12">
        <div class="form-group">
          <textarea-row :label="note" v-model="form.note" />
        </div>
      </div>
      <div class="col-lg-12">
        <div class="form-group">
          <file-input :label="resume" @file="fileSelected($event)" />
        </div>
      </div>
      <div class="col-12 mb-4 mt-2">
        <div class="accept-terms text-left">
          <input v-model="form.terms" type="checkbox" class="mr-2">
          <terms/>
        </div>
      </div>
      <div class="col-12 text-center">
        <button class="button button--main" @click="send" :disabled="!form.terms">
          {{ $store.state.statics.Send }}
        </button>
      </div>
    </div>
  </div>
</template>
<script>
import { mapState } from "vuex";
import Terms from "@/components/shared/Terms.vue";

export default {
  props: {
    id: {
      required: true,
      type: Number,
    },
  },
  data() {
    return {
      form: {
        name: "",
        surname: "",
        email: "",
        phone: "",
        note: "",
        resume: null,
        terms: false
      },
    };
  },
  methods: {
    send() {
      var l = this.form;
      let formData = new FormData();
      formData.append("VacationId", this.id);
      formData.append("Name", l.name);
      formData.append("Surname", l.surname);
      formData.append("Email", l.email);
      formData.append("Phone", l.phone);
      formData.append("Note", l.note);
      formData.append("Resume", l.resume);

      this.$store.dispatch("vacancies/sendAppeal", formData).then((res) => {
        if (res) {
          this.$bvModal.hide("vacancy-details");
          this.$bvModal.hide("vacancy-apply");
        }
      });
    },
    fileSelected(e) {
      this.form.resume = e;
    },
  },
  computed: {
    ...mapState({
      name: (state) => state.statics.Name,
      surname: (state) => state.statics.Surname,
      phone: (state) => state.statics.Phone,
      email: (state) => state.statics.Email,
      note: (state) => state.statics.Note,
      resume: (state) => state.statics.Resume,
    }),
  },
  components: {
    Terms
  }
};
</script>
