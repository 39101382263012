<template>
  <div class="vacancies">
    <Vacancies />
  </div>
</template>
<script>
import Vacancies from "@/components/vacancy/Vacancies";
export default {
  name: "vacancy",
  created() {
    this.$store.dispatch("vacancies/getVacancies");
  },
  components: {
    Vacancies,
  },
};
</script>