<template>
  <div class="col-lg-4">
    <div class="vacancy" @click="details">
      <div class="vacancy__image">
        <img :src="item.image" />
        <div class="vacancy__text">
          {{ item.title }}
        </div>
      </div>
      <div class="vacancy__bottom">
        <div class="row w-100 h-100">
          <div class="col-12 p-0">
            <div class="vacancy__button">
              {{ $store.state.statics.Details }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    item: {
      type: Object,
      required: true,
    },
    index: {
      type: Number,
      required: true,
    },
  },
  methods: {
    details() {
      this.$emit("details", this.index);
    },
  },
};
</script>